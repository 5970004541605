.App {
  text-align: center;
}
html {
  background-color: #212529;
}
.tile{
  border-radius: 25px;
  padding-bottom: 2%;
  background-color: #3d4247;
  height: 100%;
  width: 100%;
}

.tile:hover {
  background-color: #6c757d;
}

.tile-img{
  width: 90%;
  margin: auto;
  display: block;
  border-radius: 25px;
  border: 6px inset black;
}

.tile-pad{
  height: 4%;
}

.box {
  border: 1% black;
}
.padded {
  padding: 3%;
}
.tile-padded {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.centered {
  position : absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
}
.table{
  position: relative;
  text-align : center
}
.header .row{
  border-radius: 25px;
  margin: 3vw;
  background-color: #6c757d;
  min-height: 50px;
  align-content: center;
  
}

.footer .row{
  border-radius: 25px;
  margin: 3vw;
  background-color: #6c757d;
  min-height: 50px;
  align-content: center;
  
}


.footer {
  background-color: #6c757d;
  border-radius: 25px;
  margin: 3vw;
  min-height: 50px;
}

.headCols {
  color:whitesmoke;
  text-decoration: none;
  background-color: #6c757d;
  width: 100%;
  height: 10vh;
  display: flex; 
  align-items: center;
  justify-content: center;
}
.social {
  margin: 0 auto; 
  display: table; 
  text-align: center; 
  font-size:calc(10px + .7vw);
}
.hov:hover {
  background-color: #3d4247;
}

.main {
  position: relative;
}

.body {
  background-color: #212529;
  text-align:center;
  color: whitesmoke;
  min-height: 100vh;
  overflow: hidden;
}
@media only screen and (max-width: 576px) {
  .footer .row{
    margin: 0px;
    font-size: 5vw;
  }
  .footer .small {
    font-size: 3vw;
  }
  .header{
    font-size: 5vw;
    padding-bottom: 15vh;
  }
  .firstCol {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .firstFoot {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .lastCol {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

}

@media only screen and (min-width: 576px) {
  .footer{
    height: 15vw - 5vh;
    font-size: 2.8vw;
  }
  .footer .small {
    font-size: 1.8vw;
  }
  .header{
    height: 15vw - 5vh;
    font-size: 2.8vw;
  }
  .firstCol {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .firstFoot {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .lastCol {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

}
.article {
  color: whitesmoke;
}
.article-btn {
  position:absolute;
  top:10px;
  right:10px;
}

@media only screen and (max-width: 576px) {
  .tile-col {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .tile-padded{
    padding-top:0%;
    padding-bottom:0%;
  }
  .header{
    padding-top:0%;
    padding-bottom:0%;
  }
}
